/*для мр*/
html,
body,
#root {
  min-height: 100%;
}

#root {
  display: flex;
}

section.ant-layout {
  flex: 1;
  width: 100%;
}

.logo {
  height: 64px;
  background: none !important;
}

.logo span a {
  color: white !important;
  font-size: 18px;
  display: flex;
}

.ant-layout-content {
  position: relative;
}

