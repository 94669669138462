@font-face {
  font-family: Manrope;
  font-style: normal;
  src: url("./assets/fonts/Manrope-VariableFont_wght.ttf");
}

@font-face {
  font-family: Manrope;
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/Manrope-VariableFont_wght.ttf");
}

.home_content-container {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 32px;
  margin-top: 40px;
}

.home_wrapper {
  justify-content: center;
  align-items: flex-start;

  .home_section-buttons {
    display: flex;
    flex-direction: column;

    button {
      text-align: left;
      font-size: 24px;
      height: auto;
      padding: 16px;
      margin-bottom: 16px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .home_promo-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;

    .home_promo-screenshot {
      width: 100%;
      max-width: 500px;
    }

    .store-badge {
      height: 100%;
      max-height: 40px;
    }
  }
}

.new {
  font-family: "Manrope";
  position: absolute;

  bottom: 2rem;
  left: 7.5rem;
  right: 7.5rem;

  @media (max-width: 992px) {
    position: static;
    margin: 1rem 0;
  }
}

.new-version-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #deecff;
  border-radius: 1rem;
  align-items: flex-start;
  padding: 1.5rem;
  overflow: hidden;
  box-shadow: 0px 2px 8px 3px rgba(0, 0, 0, 0.15);
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: #0064f0;
    line-height: 2rem;
  }
  .text {
    font-size: 1.25rem;
    font-weight: 400;
    max-width: 32rem;
    line-height: 1.75rem;
  }

  a {
    padding: 0.5rem 1rem;
    background-color: #0064f0;
    font-size: 0.875rem;
    font-weight: 800;
    color: white;
    border-radius: 0.25rem;
    margin-top: 1rem;
  }

  img {
    position: absolute;

    &:first-of-type {
      height: calc(100% - 1rem);
      right: 26.5rem;

      bottom: -0.2rem;
    }

    &:last-of-type {
      height: calc(100% - 1rem);
      right: 1.5rem;
      bottom: 1.2rem;
    }
  }

  @media (max-width: 1570px) {
    img:first-of-type {
      display: none;
    }
  }
  @media (max-width: 1170px) {
    img:last-of-type {
      display: none;
    }
  }
}
